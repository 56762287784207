<template>
  <div>
    <!--Stats cards-->
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6" v-for="stats in statsCards">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="stats.value">
          <div class="stats" slot="footer">
            <i :class="stats.footerIcon"></i>
            {{stats.footerText}}
          </div>
        </stats-card>
      </div>
    </div>
    <!--
    <div class="row">
      <div class="col-lg-4 col-sm-6">
        <chart-card :chart-data="activeUsersChart.data"
                    chart-id="activity-chart"
                    chart-title="TOTAL EARNINGS IN LAST TEN QUARTERS">
          <span slot="title">$34,657</span>
          <badge slot="title-label" type="success">+18%</badge>

          <template slot="footer-title">Financial Statistics</template>

          <p-button slot="footer-right" aria-label="add button" type="success" round icon size="sm">
            <i class="nc-icon nc-simple-add"></i>
          </p-button>
        </chart-card>
      </div>

      <div class="col-lg-4 col-sm-6">
        <chart-card :chart-data="emailsCampaignChart.data"
                    chart-id="emails-chart"
                    color="#ef8156" chart-title="TOTAL SUBSCRIPTIONS IN LAST 7 DAYS">
          <span slot="title">169</span>
          <badge slot="title-label" type="danger">-14%</badge>

          <template slot="footer-title">View all members</template>

          <p-button slot="footer-right" aria-label="view button" type="danger" round icon size="sm">
            <i class="nc-icon nc-button-play"></i>
          </p-button>
        </chart-card>
      </div>

      <div class="col-lg-4 col-sm-6">
        <chart-card :chart-data="activeCountriesChart.data"
                    chart-id="active-countries-chart"
                    color="#fbc658" chart-title="Active Countries">
          <span slot="title">8,960</span>
          <badge slot="title-label" type="warning">~51%</badge>

          <template slot="footer-title">View more details</template>

          <p-button slot="footer-right" aria-label="view more button" type="warning" round icon size="sm">
            <i class="nc-icon nc-alert-circle-i"></i>
          </p-button>
        </chart-card>
      </div>
    </div>
    -->

    <div class="row">
      <div class="col-md-6">
        <log-list></log-list>
      </div>
      <div class="col-lg-3 col-sm-6">
        <circle-chart-card :percentage="70"
                           chart-id="email-statistics-chart"
                           title="Registered users"
                           description="New"
                           color="#4acccd">
          <template slot="footer">
            <div class="legend">
              <i class="fa fa-circle text-info"></i> Open
            </div>
            <hr>
            <div class="stats">
              <i class="fa fa-calendar"></i> Number of emails sent
            </div>
          </template>
        </circle-chart-card>
      </div>
    </div>
  </div>
</template>
<script>
import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard.vue'
import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
import {Badge} from 'src/components/UIComponents'
import Loading from 'src/components/Dashboard/Layout/LoadingMainPanel.vue'
import LogList from './Widgets/LogList'

export default {
  components: {
    StatsCard,
    ChartCard,
    CircleChartCard,
    Badge,
    LogList
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data () {
    return {
      statsCards: [
        {
          title: 'Sources',
          type: 'success',
          icon: 'nc-icon nc-single-copy-04',
          value: '',
          footerText: 'Last day',
          footerIcon: 'nc-icon nc-calendar-60',
          fetch: "GET_SOURCES"
        },
        {
          title: 'Chemicals',
          type: 'success',
          icon: 'nc-icon nc-atom',
          value: '',
          footerText: 'Updated now',
          footerIcon: 'nc-icon nc-refresh-69',
          fetch: "GET_CHEMS"
        },
        {
          title: 'Extractions',
          type: 'success',
          icon: 'nc-icon nc-layout-11',
          value: '',
          footerText: 'Updated now',
          footerIcon: 'nc-icon nc-refresh-69',
          fetch: "GET_EXTRACTIONS"
        },
        {
          title: 'Chemical Families',
          type: 'info',
          icon: 'nc-icon nc-vector',
          value: 'Not loaded yet',
          footerText: 'Updated now',
          footerIcon: 'nc-icon nc-bell-55',
          fetch: "GET_CHEMFAMILIES"
        },
        {
          title: 'Chemical Functions',
          type: 'info',
          icon: 'nc-icon nc-vector',
          value: 'Not loaded yet',
          footerText: 'Updated now',
          footerIcon: 'nc-icon nc-bell-55',
          fetch: "GET_CHEMFUNCTIONS"
        },
        {
          title: 'Master',
          type: 'danger',
          icon: 'nc-icon nc-globe',
          value: '',
          footerText: 'Updated now',
          footerIcon: 'nc-icon nc-refresh-69',
          fetch: "GET_MASTERS"
        },
        {
          title: 'Mappings',
          type: 'warning',
          icon: 'nc-icon nc-diamond',
          value: '',
          footerText: 'Updated now',
          footerIcon: 'nc-icon nc-refresh-69',
          fetch: "GET_MAPPINGS"
        },
        {
          title: 'Users',
          type: 'warning',
          icon: 'nc-icon nc-single-02',
          value: '',
          footerText: 'Updated now',
          footerIcon: 'nc-icon nc-refresh-69',
          fetch: "GET_USERS"
        },
        {
          title: 'Projects',
          type: 'warning',
          icon: 'nc-icon nc-box',
          value: '',
          footerText: 'Updated now',
          footerIcon: 'nc-icon nc-refresh-69',
          fetch: "GET_PROJECTS"
        }
      ],
      activeUsersChart: {
        data: {
          labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
          series: [542, 480, 430, 550, 530, 453, 380, 434, 568, 610]
        }
      },
      emailsCampaignChart: {
        data: {
          labels: ["12pm", "3pm", "6pm", "9pm", "12am", "3am", "6am", "9am"],
          series: [40, 500, 650, 700, 1200, 1250, 1300, 1900]
        }
      },
      activeCountriesChart: {
        data: {
          labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October"],
          series: [80, 78, 86, 96, 83, 85, 76, 75, 88, 90]
        }
      },


    }
  },
  mounted() {
    for (const statEntry of this.statsCards) {
      if(!statEntry.fetch) continue;
      this.$store.dispatch(statEntry.fetch, {t: "count"})
        .then((data)=> {
        statEntry.value = data.count.toString();
        })
      this.$store.dispatch(statEntry.fetch, {
        t: "findOne",
        s: "%7B%22updatedAt%22%3A%20-1%7D"
          /*
          {
        "updatedAt": -1
        }*/
      }).then((data)=>{
        statEntry.footerText = "Last updated " + this.$options.filters.timestampToString(data.updatedAt);
      })
    }

  }

}

</script>
<style>

</style>
