<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">My Profile</h4>
    </div>
    <div class="card-body">
      <form>

        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="ID"
                      placeholder="ID"
                      :disabled="true"
                      v-model="user._id">
            </fg-input>
          </div>
          <div class="col-md-6">

          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="Organisation"
                      placeholder="User Organisation"
                      v-model="user.organisation">
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text"
                      label="Organisation Type"
                      placeholder="Type"
                      :disabled="true"
                      v-model="user.type">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5">
            <fg-input type="email"
                      label="Email"
                      placeholder="Email"
                      v-model="user.email">
            </fg-input>
          </div>
          <div class="col-md-5">
            <fg-input type="text"
                      label="Name"
                      placeholder="Name"
                      v-model="user.name">
            </fg-input>
          </div>
          <div class="col-md-2">
            <fg-input type="text"
                      label="Role"
                      :disabled="true"
                      placeholder="User Role"
                      v-model="user.role">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <fg-input type="text"
                      :disabled="true"
                      label="Accepted Terms"
                      placeholder="Accepted Terms"
                      v-model="user.options.acceptedTerms">
            </fg-input>
          </div>
          <div class="col-md-6">

          </div>
        </div>

        <div class="text-center">

        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      user: {
        organisation: 'Paper Dashboard',
        email: '',
        name: 'Faker',
        role: 'none',
        options: {
          acceptedTerms: false
        }
      }
    }
  },
  mounted() {
    this.user = JSON.parse(JSON.stringify(this.CurrentUserX));
  },
  methods: {

  }
}

</script>
<style>

</style>

