<template>
  <card>
    <template slot="header">
      <h4 class="card-title">Logs</h4>
      <p class="category">Check errors + warnings</p>
    </template>
    <div class="table-full-width table-logs">
      <table class="table">
        <tbody>
        <log v-for="(log, index) in logs"
              :key="log.title"
              :log="log"
              :index="index"
              @on-open="handleLogOpen"
              @on-delete="handleLogDelete">

        </log>
        </tbody>
      </table>
    </div>
    <!--
    <template slot="footer">
      <hr>
      <div class="stats">
        <i class="fa fa-history"></i> Updated 3 minutes ago
      </div>
    </template>
    -->
  </card>
</template>
<script>
  import Log from './Log.vue'
  import { Card } from 'src/components/UIComponents'

  export default {
    components: {
      Log,
      Card
    },
    data() {
      return {
        logs: [
          {
            icon: 'fa fa-exclamation-circle',
            uri: 'https://api.foodpackagingforum.org/log/error.log',
            title: 'Error Log'
          },
          {
            icon: 'fa fa-exclamation-circle',
            uri: 'https://api.foodpackagingforum.org/log/warn.log',
            title: 'Warnings Log'
          },
          {
            icon: 'fa fa-exclamation-circle',
            uri: 'https://api.foodpackagingforum.org/log/combined.log',
            title: 'All Log'
          }
        ]
      }
    },
    methods: {
      handleLogOpen(index) {
        //alert(`You want to edit log: ${JSON.stringify(this.logs[index])}`)
        const log = this.logs[index];
        this.openExternal(log.uri, true)
      },
      handleLogDelete(index) {
        alert(`You want to delete log: ${JSON.stringify(this.logs[index])}`)
      }
    }
  }
</script>
<style>
</style>
