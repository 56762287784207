<template>
  <card type="user" :footerLine="true">
    <template slot="header">
      <h4 class="title">Edit Profile</h4>
    </template>

    <form>
      <div class="row">
        <div class="col-md-6">
          <fg-input type="text"
                    label="ID"
                    placeholder="ID"
                    :disabled="true"
                    v-model="user._id">
          </fg-input>
        </div>
        <div class="col-md-6">

        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input type="text"
                    label="Organisation"
                    placeholder="User Organisation"
                    v-model="user.organisation">
          </fg-input>
        </div>
        <div class="col-md-6">
          <fg-input type="text"
                    label="Organisation Type"
                    placeholder="Type"
                    :disabled="true"
                    v-model="user.type">
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5">
          <fg-input type="email"
                    label="Email"
                    placeholder="Email"
                    v-model="user.email">
          </fg-input>
        </div>
        <div class="col-md-5">
          <fg-input type="text"
                    label="Name"
                    placeholder="Name"
                    v-model="user.name">
          </fg-input>
        </div>
        <div class="col-md-2">
          <fg-input type="text"
                    label="Role"
                    :disabled="true"
                    placeholder="User Role"
                    v-model="user.role">
          </fg-input>
        </div>
      </div>

      <div class="row mt-3 mb-2">
        <div class="col-12">
          Options
        </div>
        <div class="col-md-4">
          <p-checkbox v-model="user.options.confirmed" :disabled="true">confirmed</p-checkbox>
        </div>
        <div class="col-md-4">
          <p-checkbox v-model="user.options.disabled" :disabled="true">disabled</p-checkbox>
        </div>
        <div class="col-md-4">
          <fg-input type="text"
                    label="acceptedTerms"
                    :disabled="true"
                    placeholder="acceptedTerms"
                    v-model="user.options.acceptedTerms">
          </fg-input>
        </div>

      </div>

      <div class="row mt-3 mb-3">
        <div class="col-12 text-right">
          Created: {{ this.user.updatedAt | timestampToString }} <br>
          Last Update: {{ this.user.createdAt | timestampToString }}
        </div>
      </div>
      <!--
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>About Me</label>
            <textarea rows="5" class="form-control border-input"
                      placeholder="Here can be your description"
                      v-model="user.aboutMe">

            </textarea>
          </div>
        </div>
      </div>
      -->
      <div class="text-center">

      </div>
      <div class="clearfix"></div>
    </form>

    <template slot="footer">
      <router-link class="btn btn-primary btn-fill btn-wd float-left" to="/users">Back</router-link>
      <div class="float-right btn-group">
        <!--
        <button
          type="submit"
          class="btn btn-info btn-fill btn-wd"
          @click.prevent="save"
        >
          Save
        </button>
        -->
        <drop-down class="btn-group">
          <p-button slot-scope="{isOpen}" slot="title" type="info" round class="dropdown-toggle"
                    :aria-expanded="isOpen">
            Change Role
          </p-button>
          <a
            v-for="role in Roles"
            class="dropdown-item" href="#" @click="changeRole(role)">Change from {{user.role}} => {{role}}</a>
        </drop-down>

        <button
          v-if="user.options && !user.options.confirmed"
          type="submit"
          class="btn btn-success btn-fill btn-wd"
          @click.prevent="confirm"
        >
          confirm
        </button>

        <button
          v-if="user.options && user.options.disabled"
          type="submit"
          class="btn btn-info btn-fill btn-wd"
          @click.prevent="lock(false)"
        >
          unlock
        </button>
        <button
          v-else
          type="submit"
          class="btn btn-danger btn-fill btn-wd"
          @click.prevent="lock(true)"
        >
          lock
        </button>



        <!--
        <button type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="updateProfile">
          Update Profile
        </button>
        -->
      </div>

    </template>
  </card>
</template>
<script>
  import Swal from "sweetalert2";

  export default {
    data() {
      return {
        userId: '',
        user: {
          name: "",
          email: "",
          organisation: "",
          role: "",
        }
      }
    },
    mounted() {
      this.userId = this.$route.params.id
      this.$store.dispatch('GET_USER', this.userId)
        .then((response) => {
          Logger.debug("response", response)
          this.user = response
        })
        .catch((error) => {
          console.error("error", error)
        })
    },
    computed: {
      Roles() {
        const allRoles = [
          'guest',
          'user',
          'moderator',
          'admin',
          'dev'
        ];
        const selectableRoles = [];

        for(let i = 0; i < allRoles.length; i++) {
          if(allRoles[i] !== this.user.role) {
            selectableRoles.push(allRoles[i]);
          }
        }
        return selectableRoles
      },
    },
    methods: {
      save() {
        throw new Error("not implemented");
        /*
        const userId = this.userId;
        this.LoadingX = true;
        this.$store
          .dispatch("CONFIRM_USER_ADMIN", {userId})
          .then(val => {
            this.user = val;
            this.$notify({
              title: "User confirmed",
              message:'User ' + this.user.name + '(' + this.user.email + ') confirmed.',
              type: "success",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
          })
          .catch(error => {
            //this.$errorHandler.defaultLLocalApiErrorHandler(this, error, "expire catalog item");
            Logger.debug("error", error);

            this.$notify({
              title: "User confirmation failed",
              message:'Please get in touch with the admin.',
              type: "danger",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
          })
          .finally(() => {
            // using "finally" so even if there are errors, it stops "loading"
            //Logger.trace("disableItemGeneric finally");
            this.LoadingX = false;
          });

         */
      },
      confirm() {
        Swal.fire({
          title: "Are you sure?",
          text: "You are about to confirm the user: " + this.user.name + '(' + this.user.email + ")",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#6D6E70",
          confirmButtonText: "Yes, confirm user"
        }).then((result) => {
          if (result.value) {
            const userId = this.userId;
            this.LoadingX = true;
            this.$store
              .dispatch("CONFIRM_USER_ADMIN", {userId})
              .then(val => {
                this.user = val;
                this.$notify({
                  title: "User confirmed",
                  message:'User ' + this.user.name + '(' + this.user.email + ') confirmed.',
                  type: "success",
                  timeout: 3000,
                  horizontalAlign: "right",
                  verticalAlign: "top",
                  icon: 'nc-icon nc-app',
                });
              })
              .catch(error => {
                //this.$errorHandler.defaultLLocalApiErrorHandler(this, error, "expire catalog item");
                Logger.debug("error", error);

                this.$notify({
                  title: "User confirmation failed",
                  message:'Please get in touch with the admin.',
                  type: "danger",
                  timeout: 3000,
                  horizontalAlign: "right",
                  verticalAlign: "top",
                  icon: 'nc-icon nc-app',
                });
              })
              .finally(() => {
                // using "finally" so even if there are errors, it stops "loading"
                //Logger.trace("disableItemGeneric finally");
                this.LoadingX = false;
              });
          }
        });



      },
      lock(locked) {
        Swal.fire({
          title: "Are you sure?",
          text: "You are about to " + (locked ? "lock" : "unlock") + " this user!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#6D6E70",
          confirmButtonText: "Yes, " + (locked ? "lock" : "unlock") + " it!"
        }).then((result) => {
          if (result.value) {
            let payloadObj = {
              userId: this.userId,
              disabled: locked
            };
            this.LoadingX = true;
            this.$store
              .dispatch("DISABLE_USER_ADMIN", payloadObj)
              .then(val => {
                this.user = val;
                this.$notify({
                  title: "User " + (locked ? "locked" : "unlocked"),
                  message:'User ' + this.user.name + '(' + this.user.email + ') ' + (locked ? "locked" : "unlocked"),
                  type: "success",
                  timeout: 3000,
                  horizontalAlign: "right",
                  verticalAlign: "top",
                  icon: 'nc-icon nc-app',
                });
              })
              .catch(error => {
                //this.$errorHandler.defaultLLocalApiErrorHandler(this, error, "expire catalog item");
                Logger.debug("error", error);

                this.$notify({
                  title: "Un/Lock failed",
                  message:'Please get in touch with the admin.',
                  type: "danger",
                  timeout: 3000,
                  horizontalAlign: "right",
                  verticalAlign: "top",
                  icon: 'nc-icon nc-app',
                });
              })
              .finally(() => {
                // using "finally" so even if there are errors, it stops "loading"
                //Logger.trace("disableItemGeneric finally");
                this.LoadingX = false;
              });
          }
        });


      },
      changeRole(role) {
        let payloadObj = {
          userId: this.userId,
          role
        };
        this.LoadingX = true;
        this.$store
          .dispatch("CHANGE_ROLE_USER_ADMIN", payloadObj)
          .then(val => {
            this.user = val;
            this.$notify({
              title: "Change role to " + role,
              message:'Change role of user ' + this.user.name + ' from ' + this.user.role + ' to ' + role,
              type: "success",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
          })
          .catch(error => {
            //this.$errorHandler.defaultLLocalApiErrorHandler(this, error, "expire catalog item");
            Logger.debug("error", error);
            this.$notify({
              title: "Change role failed",
              message:'Please get in touch with the admin.',
              type: "danger",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
          })
          .finally(() => {
            // using "finally" so even if there are errors, it stops "loading"
            //Logger.trace("disableItemGeneric finally");
            this.LoadingX = false;
          });
      }
    }
  }

</script>
<style>

</style>
