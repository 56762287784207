<template>
  <tr>
    <td class="img-row">
      <i :class="log.icon"></i>
      <!--
      <div class="img-wrapper">
        <img :src="log.img" class="img-raised" :alt="log.title">
      </div>
      -->
    </td>
    <td class="text-left">{{log.title}}</td>
    <td class="td-actions text-right">
      <el-tooltip content="Open" placement="top">
        <p-button type="info" aria-label="open button" round icon class="btn-icon-mini btn-neutral" @click="handleOpenClick">
          <i class="nc-icon nc-cloud-download-93"></i>
        </p-button>
      </el-tooltip>
      <!--
      <el-tooltip placement="top" content="Remove">
        <p-button type="danger" aria-label="remove button" round icon class="btn-icon-mini btn-neutral" @click="handleDeleteClick">
          <i class="nc-icon nc-simple-remove"></i>
        </p-button>
      </el-tooltip>
      -->
    </td>
  </tr>
</template>
<script>
  import { Tooltip } from 'element-ui'

  export default {
    components: {
      [Tooltip.name]: Tooltip
    },
    props: {
      log: {
        type: Object,
        default: () => {
          return {
            title: ''
          }
        }
      },
      index: Number
    },
    methods: {
      handleOpenClick() {
        this.$emit('on-open', this.index)
      },
      handleDeleteClick() {
        this.$emit('on-delete', this.index)
      }
    }
  }
</script>
<style>
</style>
