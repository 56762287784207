<template>
    <div class="row">
      <div class="col-12">
        <h3>Tools</h3>
      </div>

      <div class="col-md-6">

      </div>
      <div class="col-md-6">

      </div>
    </div>
</template>
<script>

  import adminAPI from "@/js/api/admin";
  import Swal from "sweetalert2";

  export default {
    components: {


    },
    mixins: [],
    data () {
      return {

      }
    },
    mounted() {},
    computed: {},
    methods: {

    }
  }

</script>
<style>
</style>
